$(() => {
    'use strict';

    $(document).foundation();

    new ScrollToTargetController();
    new ContactForm();

    $(window)
        .on('scroll', (event) => {
            if($(window).scrollTop() > 0) {
                $('#main-navigation').addClass('has-shadow');
            } else {
                $('#main-navigation').removeClass('has-shadow');
            }
        })
        .trigger('scroll');

    $('#language-selection a').each((index, menuItem) => {
        $(menuItem).on('click', (e) => {
            let selectedLanguage = $(e.target).data('value');
            let split = location.href.split('/');
            let fileName = split[split.length -1]
            let targetFileName = '';

            if(fileName.match(/^index/) !== null) {
                targetFileName = selectedLanguage == 'de' ? 'index.html' : 'index-en.html';
            } else {
                targetFileName = selectedLanguage == 'de' ? 'info.html' : 'info-en.html';
            }

            location.href = targetFileName;
        });
    });
});

class ScrollToTargetController {
    constructor() {
        this.dataAttribute = 'data-scroll-target';
        this.scrollDuration = 500;
        this.scrollTriggerLinks = $('[' + this.dataAttribute + ']');
        this.addEvents();
    }

    addEvents() {
        this.scrollTriggerLinks.each((i, trigger) => {
            $(trigger).on('click', (e) => {
                e.preventDefault();
                let targetId = $(trigger).attr(this.dataAttribute);
                let targetElement = $('#' + targetId);
                this.scrollToElement(targetElement);
            });
        });
    }

    scrollToElement(targetElement, offset) {
        $('html, body').animate({
            scrollTop: targetElement.offset().top
        }, this.scrollDuration);
    }
}

class ContactForm {
    constructor() {
        this.form = $('#contact-form');
        this.submitButton = $('#submit-contact-form');
        this.overlay = $('#uic-sending-overlay');
        this.successMessage = $('#contact-success-message');
        this.nameField = $('#user-name');
        this.companyNameField = $('#company-name');
        this.emailField = $('#user-email');
        this.messageField = $('#user-message');
        this.addEvents();
    }

    addEvents() {
        this.form.on('submit', (e) => {
            e.preventDefault();
            if(this.isFormValid()) {
                this.overlay.fadeIn(200);
                this.sendMessage().then(() => {
                    this.form.fadeOut(200, () => this.successMessage.fadeIn(200));
                });
            }
        });
    }

    sendMessage() {
        return new Promise((resolve, reject) => {
            $.post('contact_mail.php', {
                data: {
                    'name': this.nameField.val(),
                    'company': this.companyNameField.val(),
                    'email': this.emailField.val(),
                    'message': this.messageField.val()
                }
            }, resolve);
        })
    }

    isFormValid() {
        return this.form.get(0).checkValidity();
    }
}